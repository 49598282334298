<style lang="scss">
.werbebeilagen {
  h2 {
  }
  .tabelle-box {
    strong {
      padding-left: 0 !important;
    }
    .col-lg-4 {
      @media (max-width: 992px) {
        text-align: right !important;
      }
    }
    .small-text-align-center {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
  .tabelle-with-80perc {
    width: 80%;
    margin: 40px auto !important;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="werbebeilagen content container">
    <h1>Werbebeilagen</h1>
    <p class="intro-txt">Wussten Sie, dass die meisten Kaufentscheidungen am Morgen getroffen werden? Mit einer Beilage in der Kleinen Zeitung erreichen Sie Ihre Kunden bereits am Frühstückstisch.</p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/werbebeilagen.jpg" alt="Werbebeilagen" class="img-fluid" />
    </div>
    <ul class="content-ul">
      <li><strong>Optimale Basisverteilung mit hoher Reichweite:</strong> 45,2 % in der Steiermark und 48,1 % in Kärnten.*</li>
      <li><strong>Tägliche Zustellung:</strong> Von Montag bis Sonntag – suchen Sie sich einfach Ihren Tag für Ihre Zeitungsbeilage oder Zusatzverteilung aus.</li>
      <li><strong>Frühstückstisch-Garantie:</strong> Bevor die Sonne aufgeht, liegt Ihre Beilage schon vor der Tür – mit einer garantierten Zustellung zwischen 4 und 6 Uhr früh.</li>
      <li><strong>Branchen-Exklusivität und Mengenbeschränkung:</strong> Ihr Prospekt erscheint am gebuchten Tag als <strong>einziges in Ihrer Branche.</strong> Pro Ausgabe sind <strong>maximal fünf Beilagen möglich</strong> – in Summe ein Branchenmix mit hoher Aufmerksamkeitsspanne.</li>
    </ul>
    <div class="quelle-txt">Quelle: MA 2019, Erhebungszeitraum Jänner bis Dezember 2019. Die angegebenen Werte unterliegen statistischen Schwankungsbreiten, siehe dazu http://media-analyse.at/Signifikanz</div>

    <div class="whitespace"></div>
    <h2>Beilegekosten</h2>
    <div class="row">
      <div class="col-lg-6 justify-content-center">
        <div class="tabelle-box tabelle-with-80perc">
          <div class="headline">
            unter 80.000 Stück
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">Grammatur</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">20 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">118,80</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">123,90</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">40 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">127,65</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">133,15</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">60 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">139,35</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">145,40</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">80 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">151,30</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">157,70</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">100 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">172,00</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">179,20</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">120 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">185,30</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">193,00</div>
          </div>
          <!--<div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">140 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">203,00</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">211,40</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">160 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">220,68</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">229,80</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">180 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">238,38</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">248,20</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">200 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">256,08</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">266,60</div>
          </div>
          -->
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box tabelle-with-80perc">
          <div class="headline">
            über 80.000 Stück
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">Grammatur</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">20 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">117,00</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">122,10</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">40 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">125,80</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">131,20</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">60 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">137,40</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">143,25</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">80 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">149,05</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">155,35</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">100 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">169,70</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">176,10</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">120 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">183,00</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">189,90</div>
          </div>
          <!--<div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">140 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">200,70</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">207,90</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">160 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">218,38</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">225,90</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">180 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">236,08</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">243,90</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">200 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">253,78</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">261,90</div>
          </div>
          -->
        </div>
      </div>
      <div class="col-lg-12">
        <h2>Zusatzverteilung</h2>
        <p><strong>Einfach mehr erreichen.</strong><br />Erreichen Sie auch Haushalte, die keine oder eine andere Tageszeitung erhalten. Eine zusätzliche Verteilung bietet Ihnen eine Verdichtung und Ergänzung Ihres Streugebiets um weitere 368.261 Haushalte. Jene Haushalte, die Ihre Beilage bereits mit der Kleinen Zeitung erhalten haben, werden nicht doppelt beliefert.</p>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box tabelle-with-80perc">
          <div class="headline">
            unter 80.000 Stück
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">Grammatur</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">20 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">87,80</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">91,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">40 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">93,85</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">97,65</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">60 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">102,30</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">106,35</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">80 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">110,65</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">115,05</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">100 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">125,10</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">130,37</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">120 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">134,40</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">140,25</div>
          </div>
          <!--<div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">140 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">146,80</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">153,41</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">160 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">159,20</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">166,58</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">180 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">171,60</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">179,74</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">200 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">184,00</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">192,91</div>
          </div>-->
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box tabelle-with-80perc">
          <div class="headline">
            über 80.000 Stück
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">Grammatur</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">20 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">86,50</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">89,70</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">40 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">92,45</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">96,20</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">60 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">100,80</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">104,80</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">80 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">109,00</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">113,35</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">100 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">123,28</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">128,40</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">120 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">132,50</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">138,10</div>
          </div>
          <!--<div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">140 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">144,8</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">151</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">160 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">157,06</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">163,88</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">180 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">169,3</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">176,78</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Grammatur</div>
            <div class="col-lg-4 col-6 text-align-left">200 g</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">181,6</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">189,68</div>
          </div>
          -->
        </div>
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, per 1000 Stück, exklusive PR- und Druckkosten, 5% Werbeabgabe und 20 % Umsatzsteuer. Höhere Grammaturen auf Anfrage.</div>
    <div class="info-box-spezifikationen"><router-link :to="{ name: 'Spezifikationen' }">Informationen zu den Spezifikationen finden Sie hier.</router-link></div>
    <div class="btn-rechner">
      <div class="head-txt">Jetzt den Preis Ihrer Beilage sofort berechnen</div>
      <div class="btn-main">
        <router-link :to="{ name: 'calculator' }"
          ><i class="material-icons">calculate</i><span>Druckkosten-<strong>Rechner</strong></span></router-link
        >
      </div>
    </div>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/beilagen/Werbebeilagen-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Werbebeilagen
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>680 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';

export default defineComponent({
  name: 'Werbebeilagen',
  components: {
    //Modal,
  },
});
</script>
